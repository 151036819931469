/* eslint-disable react/prop-types */
import React from 'react';

import NotFound from '../errors/notfound';
import {SectionTitle} from '../dashboard/SectionTitle';

function Feed({goalId, title, posts, handleClickMorePosts, showLoadMoreButton}) {
  return (
    <div key={`Feed-${goalId === undefined ? 'all' : goalId}`} className="section-feed dashboard-section">
      <div className="container">
        {title && <SectionTitle title={title} />}

        {posts.length > 0 ? posts : <NotFound text="Create a post or a profile to get stared!" profileLink />}

        {showLoadMoreButton && (
          <button type="button" className="button -tertiary -tiny" onClick={handleClickMorePosts}>
            Load more
          </button>
        )}
      </div>
    </div>
  );
}

export default Feed;
