import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {SectionTitle} from '../SectionTitle';
import {profileStyles} from './ProfilesStyled';
import {SingleProfile} from './SingleProfile';

function ProfilesGroup({profiles, title}) {
  const [filterByName, setFilterByName] = useState('');
  const classes = profileStyles();

  const filteredProfiles = useMemo(() => {
    if (!filterByName || !profiles.length) {
      return profiles || [];
    }
    return profiles.filter(profile => `${profile.firstname} ${profile.lastname}`.toLowerCase().includes(filterByName.toLowerCase()));
  }, [filterByName, profiles]);

  const height = useMemo(() => Math.min(profiles.length, 5) * 78 + 25, [profiles]);

  if (!profiles?.length) {
    return null;
  }
  return (
    <>
      <SectionTitle title={title} />
      <Box className={classes.profilesWrapper}>
        {profiles.length > 5 && <input type="text" placeholder="Filter by name" onChange={e => setFilterByName(e.target.value)} value={filterByName} />}
        <Box className={classes.profilesContainerStyle} height={height}>
          {filteredProfiles.map(profile => (
            <SingleProfile profile={profile} key={profile.id} />
          ))}
        </Box>
      </Box>
    </>
  );
}

ProfilesGroup.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
};

export {ProfilesGroup};
export default ProfilesGroup;
