import React from 'react';
import {Box} from '@material-ui/core';
import {SectionTitle} from '../SectionTitle';

import CreatePostForm from './CreatePostForm';
import {useStyles} from '../WelcomeStyled';

function CreatePost() {
  const classes = useStyles();
  return (
    <Box className={classes.createPostContainer}>
      <SectionTitle title="Create Post" />
      <CreatePostForm />
    </Box>
  );
}

export default CreatePost;
