/* eslint-disable react/prop-types */
import React from 'react';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import _ from 'lodash';
import {Card, Icon} from '../common';
import {getFullName} from '../../helpers/getFullName';
import withAuthorization from '../session/withAuthorization';
import routes from '../../constants/routes';

import blankProfile from '../../assets/img/blank-profile.png';

import '../../assets/scss/components/professional/clients.scss';
import '../../assets/scss/components/professional/client-list.scss';
import LinkAsButton from '../buttons/LinkAsButton';

class Professional extends React.Component {
  state = {
    clientFilter: '',
  };

  componentDidMount() {
    const {
      stores: {uiStore},
    } = this.props;
    // eslint-disable-next-line fp/no-mutation
    uiStore.currentProfileId = null;
  }

  setClientFilter = clientFilter => {
    this.setState({
      clientFilter: clientFilter.toLowerCase(),
    });
  };

  render() {
    const {clientFilter} = this.state;
    const {
      authUser,
      stores: {profilesStore},
    } = this.props;

    const sortedProfilesByFirstName = _.orderBy(profilesStore.profiles, profile => getFullName(profile?.profileSections?.essentials).toLowerCase());

    const clientProfiles = sortedProfilesByFirstName
      .filter(profile => profile.permission && profile.permission.type === 'professional')
      .filter(profile => clientFilter === '' || getFullName(profile.profileSections?.essentials).toLowerCase().indexOf(clientFilter) !== -1);
    return (
      <div className="page -professional -clients">
        <div className="container">
          <div className="profile-header">
            <div className="container">
              <div className="image">
                {authUser.profileImagePublic && (
                  <div
                    className="profile-image"
                    style={{
                      backgroundImage: `url(${authUser.profileImagePublic})`,
                    }}
                  />
                )}
                &nbsp;
              </div>
              <div className="name">
                <h1>{getFullName(authUser)}</h1>
                {authUser.organisation && <h3>{authUser.organisation.name}</h3>}
              </div>
            </div>
          </div>
          <div className="profile-nav">
            <div className="container">
              <ul className="menu-secondary">
                <li>
                  <Link to={routes.professional.clients}>Clients</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="client-list">
            <input
              className="client-list__filter"
              type="search"
              placeholder="Search"
              value={clientFilter}
              onChange={e => this.setClientFilter(e.target.value)}
            />
            <h4 className="client-list__heading">Clients &#47; Families You Support</h4>
            {clientProfiles.map(profile => (
              <Link
                key={profile.id}
                to={routes.generate(routes.profile.home, {
                  profileId: profile.id,
                })}>
                <Card full>
                  <div
                    className="client-list__image"
                    style={{
                      backgroundImage: `url(${profile.profileSections?.essentials?.profileImage?.url || blankProfile})`,
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="client-list__name">{getFullName(profile.profileSections?.essentials)}</label>
                </Card>
              </Link>
            ))}
            {clientProfiles.length < 1 && <p className="client-list__empty">No clients found</p>}
            <LinkAsButton url="https://www.sameview.com.au/inviteclient" text="Invite Client" icon={<Icon type="mail" />} />
          </div>
        </div>
      </div>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(Professional)));
