/* eslint-disable fp/no-mutation */
import {toJS} from 'mobx';
import React, {useCallback, useEffect, useState} from 'react';
import {updateStep} from '../firebase/db';
import {db} from '../firebase';
import {getActiveProfileFromParams} from '../helpers/getActiveProfileFromParams';
import getIsViewer from '../helpers/getIsViewer';
import {PLACEHOLDER_PNG_SRC} from '../constants/consts';
import {getExt} from '../helpers/helperFunctions';
import routes from '../constants/routes';
import {errorLogger} from '../helpers/errorLogger';
import GoalSelection from '../components/goal/goal.post.selection';
import {Avatar} from '../components/common';
import {onFileProgress} from '../helpers/fileHandling/onFileProgress';

const useCreatePost = ({stores: {profilesStore}, onboard, match, history, authUser}) => {
  const [activeProfile, setActiveProfile] = useState(null);
  const [selectedSteps, setSelectedSteps] = useState({});
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [uploads, setUploads] = useState({});
  const [extended, setExtended] = useState(false);
  const [tags, setTags] = useState([]);
  const [isViewingProfile, setIsViewingProfile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isViewer, setIsViewer] = useState(false);

  useEffect(() => {
    const profileFromParams = getActiveProfileFromParams({stores: {profilesStore}, match});
    Object.keys(toJS(profileFromParams.goals) || {}).forEach(goalId => {
      const goal = profileFromParams.goals[goalId];
      Object.keys(goal.steps || {}).forEach(stepIndex => {
        if (!goal.steps[stepIndex].id) {
          updateStep(profileFromParams.id, goalId, stepIndex, {id: db.createUUID()});
        }
      });
    });
    setActiveProfile(profileFromParams);
  }, [match, profilesStore]);

  useEffect(() => {
    if (activeProfile) {
      setIsViewer(getIsViewer(activeProfile));

      const newTags = Object.keys(activeProfile?.team ?? {}).map(id => ({
        id,
        display: `${activeProfile.team[id].firstname} ${activeProfile.team[id].lastname}`,
        profileImagePublic: activeProfile.team[id].profileImagePublic,
      }));

      setTags(newTags);
    }
  }, [activeProfile]);

  useEffect(() => {
    setIsViewingProfile(!!match.params.profileId);
  }, [match]);

  useEffect(() => {
    const isFileUploading = () => !!Object.values(uploads).find(({done}) => !done);
    setIsUploading(isFileUploading);
  }, [uploads]);

  const handleUpdateSelectedProfile = (e, id) => {
    const profileId = e?.target?.value || id;
    const newActiveProfile = profilesStore.profiles.find(p => p.id === profileId);
    setActiveProfile(newActiveProfile);
    setSelectedSteps({});
  };

  const handleClickStep = useCallback((stepId, name) => {
    // Want to toggle the active steps here
    setSelectedSteps(prev => ({
      ...prev,
      [stepId]: prev[stepId] ? false : name,
    }));
  }, []);

  const onFileUpload = useCallback((file, url, storagePath) => {
    if (file.type.match(/(image|video).*/)) {
      URL.revokeObjectURL(file);
    }

    setUploads(prevState => ({
      ...prevState,
      [file.uniqFileName]: {
        ...prevState[file.uniqFileName],
        storagePath,
        uniqFileName: file.uniqFileName,
        postFilePublic: url,
        postFile: file.name,
        ext: getExt(file.name),
        type: file.type,
        done: true,
      },
    }));
  }, []);

  const handleOnFileProgress = useCallback((file, progress) => {
    const fileUpload = onFileProgress(file, progress);
    setUploads(prevState => ({...prevState, ...fileUpload}));
  }, []);

  const onFileError = (file, newError) =>
    setUploads(prevState => ({
      ...prevState,
      [file.uniqFileName]: {
        ...prevState[file.uniqFileName],
        postFilePreview: PLACEHOLDER_PNG_SRC,
        postFilePublic: null,
        postFile: file.name,
        ext: getExt(file.name),
        type: file.type,
        error: newError,
      },
    }));

  const onFileDelete = file =>
    setUploads(prevState => {
      const DeletedUploads = {...prevState};
      // eslint-disable-next-line fp/no-delete
      delete DeletedUploads[file.uniqFileName];
      return DeletedUploads;
    });

  const setExtensions = value => {
    setExtended(value.length > 0);
  };

  const onSubmit = event => {
    event.preventDefault();
    const id = db.createUUID();
    const {uid} = authUser;

    const mentions = (text || '').split('@[').map((str = '') => {
      if (str.length === 0) return {text: ''};
      if (str.split('](').length === 1) return {text: str};
      const name = str.split('](')[0];
      const newId = str.split('](')[1].split(')')[0];
      return {
        name,
        id: newId,
        text: str.replace(name, '').replace(newId, '').replace(']()', ''),
      };
    });
    const mentionedFiltered = mentions.filter(obj => obj.id);
    const filteredSelectedTeamMembers = mentionedFiltered.length > 0 ? {} : null;
    mentionedFiltered.forEach(obj => {
      filteredSelectedTeamMembers[obj.id] = true;
    });
    // Determine the goals that were used given the selected steps.
    // Better to "compute" this here than try and keep it in sync
    // whilst selecting steps
    const selectedGoalsObj = Object.entries(activeProfile.goals || {}).reduce((accum, [currGoalKey, currGoalValue]) => {
      if (Object.values(currGoalValue.steps || {}).some(step => selectedSteps[step.id])) {
        // eslint-disable-next-line no-param-reassign
        accum[currGoalKey] = true;
        return accum;
      }
      return accum;
    }, {});

    const newUploads = Object.values(uploads).map(({postFilePublic, postFilePublicLqip = null, postFile, storagePath, ext, type}) => ({
      storagePath,
      postFilePublicLqip,
      postFilePublic,
      postFile,
      ext,
      type,
    }));

    const onlySelectedSteps = Object.entries(selectedSteps).reduce((nextSteps, [stepId, isSelected]) => {
      if (isSelected) {
        return {
          ...nextSteps,
          [stepId]: true,
        };
      }
      return nextSteps;
    }, {});

    // Create a new post
    db.createPost({
      id,
      profileId: activeProfile.id,
      authorId: uid,
      text,
      steps: onlySelectedSteps,
      goals: selectedGoalsObj,
      members: filteredSelectedTeamMembers,
      uploads: newUploads,
    })
      .then(() => {
        if (onboard) {
          // eslint-disable-next-line fp/no-mutating-methods
          return history.push(
            routes.generate(routes.profile.onboard.done, {
              profileId: activeProfile.id,
            })
          );
        }

        // Reset the form state but keep the active profile set.
        setActiveProfile(activeProfile);
        setSelectedSteps({});
        setText('');

        setUploads({});
        setExtended(false);
        setTags([]);
        setIsViewingProfile(false);
        setIsUploading(false);

        // eslint-disable-next-line fp/no-mutating-methods
        history.push(
          routes.generate(routes.profile.home, {
            profileId: activeProfile.id,
          })
        );

        return true;
      })
      .catch(e => {
        errorLogger(e);
        setError(e);
      });
  };

  const renderGoals = () => {
    if (!activeProfile || !activeProfile.goals) return null;
    const selected = Object.entries(selectedSteps)
      .map(([id, step]) => ({id, step}))
      .filter(obj => obj.step);
    return (
      <GoalSelection
        profileId={activeProfile.id}
        goals={activeProfile.goals}
        handleClickStep={handleClickStep}
        selectedSteps={selectedSteps}
        selectedFiltered={selected}
      />
    );
  };

  const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => (
    <>
      <Avatar className="comments-textarea__suggestions__item__image" imageClassName="img" src={entry.profileImagePublic} />
      <div className="comments-textarea__suggestions__item__text">{highlightedDisplay}</div>
    </>
  );
  return {
    activeProfile,
    text,
    error,
    uploads,
    extended,
    tags,
    isViewingProfile,
    isUploading,
    isViewer,
    handleUpdateSelectedProfile,
    onFileUpload,
    handleOnFileProgress,
    onFileError,
    onFileDelete,
    setExtensions,
    setText,
    onSubmit,
    renderGoals,
    renderUserSuggestion,
  };
};

export default useCreatePost;
