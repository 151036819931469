/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, {useState, Fragment, useEffect, useCallback, useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import {FileIcon, defaultStyles} from 'react-file-icon';
import {Link} from 'react-router-dom';
import Observer from 'react-intersection-observer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import routes from '../../constants/routes';

import {Gallery, Avatar} from '../common';
import Comment from '../comment';
import CommentCreate from '../comment/comment.create';
import {db} from '../../firebase';
import withAuthorization from '../session/withAuthorization';
import {PostDeleteButton, PostDeleteModal} from './post.delete';
import {PostEditButton, PostEditModal} from './post.edit';
import PostActions from './post.actions';
import GoalComplete from './post.goal.complete';
import GoalCreated from './post.goal.created';
import GoalMentioned from './post.goal.mentioned';
import UserInvited from './post.user.invited';
import NewUserWelcomeBanner from './post.new.profile';
import {UserNameLink} from '../user/user.link';
import {errorLogger} from '../../helpers/errorLogger';
import PostPinButton from './post.pin';
import getIsOwner from '../../helpers/getIsOwner';

import renderText from '../../helpers/renderText';

dayjs.extend(relativeTime);

function PostFile({postFile, postFilePublic, ext}) {
  return (
    <a className="files__link" href={postFilePublic} target="_blank" rel="noopener noreferrer">
      <FileIcon extension={ext} {...defaultStyles[ext]} />
      <span className="files__name">{postFile}</span>
    </a>
  );
}

function Post({data, profile, authUser, mode, postClass, stores: {notificationsStore, usersStore, profilesStore}, isDashboardScreen}) {
  const [comments, setComments] = useState({});
  const [commentEnabled, setCommentEnabled] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorLiking, setErrorLiking] = useState(false);
  const [maxComments, setMaxComments] = useState(5);
  const [timer, setTimer] = useState(null);

  const author = usersStore.getUserById(data.authorId);

  const renderComments = useCallback(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      Object.keys(comments || {})
        .map(commentKey => {
          const comment = {
            ...comments[commentKey],
            id: commentKey,
          };
          return comment;
        })
        .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
        .filter((_, index, arr) => arr.length - 1 - index < maxComments)
        .map(comment => <Comment data={comment} profile={profile} postId={data.id} key={comment.id} />),
    [comments, profile, data.id, maxComments]
  );

  const renderedComments = renderComments();
  const totalComments = useMemo(() => Object.keys(comments || {}).length, [comments]);

  // TODO_MB : Show more comments button
  // I set a limit of 5 comments to display.  We can take this out if
  // not needed.  I just had some huge comment lists and it made the
  // feed look not as nice.  JH

  const showMoreCommentsButton = useMemo(
    () =>
      totalComments > renderedComments.length ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="showmore" onClick={() => setMaxComments(Infinity)}>
          Show all {totalComments} comments
        </div>
      ) : null,
    [totalComments, renderedComments.length]
  );

  const gallery = Array.isArray(data.uploads) ? data.uploads.filter(upload => upload.type.match(/(image|video).*/)) : {};
  const files = Array.isArray(data.uploads) ? data.uploads.filter(upload => !upload.type.match(/(image|video).*/)) : {};
  const notification = notificationsStore.getNotificationByPostId(data.id);
  const hasNotificationClass = notification && notification.seen === false ? 'unread' : 'read';
  const date = dayjs().diff(dayjs(data.created_at), 'd') >= 2 ? dayjs(data.created_at).format("DD MMM 'YY") : dayjs(data.created_at).fromNow();
  const editDate = dayjs().diff(dayjs(data.updated_at), 'd') >= 2 ? `on ${dayjs(data.updated_at).format("DD MMM 'YY")}` : dayjs(data.updated_at).fromNow();

  const handleUpdateComments = useCallback(
    snapshot => {
      const newComments = snapshot.val();
      usersStore.fetchUsersFromCommentData(newComments);
      setComments(newComments);
    },
    [usersStore]
  );

  useEffect(() => {
    db.subscribeToPostComments(data.id, handleUpdateComments);
  }, [data.id, handleUpdateComments]);

  const onLikeClick = useCallback(
    event => {
      setErrorLiking(false);

      event.preventDefault();
      const {uid} = authUser;

      const alreadyLiked = data.likes && data.likes[uid];

      if (alreadyLiked) {
        db.removePostLike({
          profileId: profile.id,
          postId: data.id,
          uid,
        }).catch(error => {
          errorLogger(error);
          setErrorLiking(true);
        });
      } else {
        db.createPostLike({
          profileId: profile.id,
          postId: data.id,
          uid,
        }).catch(error => {
          errorLogger(error);
          setErrorLiking(true);
        });
      }
    },
    [authUser, data, profile.id]
  );

  const onDeleteClick = useCallback(event => {
    setDeleteModalOpen(true);
  }, []);

  const onDeleteConfirm = useCallback(() => {
    db.deletePost({
      postId: data.id,
      profileId: profile.id,
    });
    setDeleteModalOpen(false);
  }, [data, profile]);

  const onPinToggle = useCallback(() => {
    db.setPostPin({pinned: !data.pinned, postId: data.id, profileId: profile.id, text: data.text});
  }, [data, profile]);
  const onEditClick = useCallback(event => {
    setEditModalOpen(true);
  }, []);

  const onEditConfirm = useCallback(
    ({text}) => {
      db.updatePost({
        text,
        id: data.id,
        profileId: profile.id,
        pinned: data.pinned,
      });
      setEditModalOpen(false);
    },
    [data.id, data.pinned, profile.id]
  );

  const toggleCommentEnabled = useCallback(() => {
    setCommentEnabled(!commentEnabled);
  }, [commentEnabled]);

  const onViewChange = useCallback(
    (inView, postId) => {
      const newNotification = notificationsStore.getNotificationByPostId(postId);

      if (!newNotification) return false;

      if (inView) {
        setTimer(
          setTimeout(() => {
            db.setNotificationSeen(authUser.uid, newNotification.id, true);
          }, 3000)
        );
      } else {
        clearTimeout(timer);
        setTimer(null);
      }

      return true;
    },
    [authUser.uid, notificationsStore, timer]
  );

  const generateAuthorList = useCallback(() => {
    const profileId = profile.id;

    const teamMembersIds = Object.keys(data.members || {});

    const teamMemberNames = teamMembersIds
      .map(userId => usersStore.getUserById(userId))
      .map(m => (m ? <UserNameLink key={m.id} user={m} profileId={profileId} /> : null));
    // TODO: What happens if a team member is removed from the team . In that case I just don't render here
    // do we need a better solution?

    const profileAndUser = <UserNameLink user={author} profileId={profileId} />;
    if (data.memberInvited) {
      return (
        <>
          {/* <ProfileNameLink profile={profile} />
          's GET_RELATIONSHIP, */}
          {teamMemberNames} has been added to the team:
        </>
      );
    }

    if (!data.complete && data.goals && data.steps && data.newGoal) {
      const goalId = Object.keys(data.goals)[0];
      const goal = profile?.goals?.[goalId]?.name;
      if (goal) {
        return (
          <>
            {profileAndUser}
            <span> added a new goal: </span>
            <Link
              className="goal-link"
              to={routes.generate(routes.profile.goal.detail, {
                profileId,
                goalId,
              })}>
              {goal}
            </Link>
          </>
        );
      }
    }

    if (data.complete && data.goals && data.steps) {
      const goalId = Object.keys(data.goals)[0];
      const goal = profile?.goals?.[goalId]?.name;
      if (goal) {
        return (
          <>
            {profileAndUser}
            <span> completed a goal: </span>
            <Link
              className="goal-link"
              to={routes.generate(routes.profile.goal.detail, {
                profileId,
                goalId,
              })}>
              {goal}
            </Link>
          </>
        );
      }
    }

    if (!data.complete && data.goals && data.steps && !data.newGoal) {
      const goalIds = Object.keys(data.goals || {});
      if (goalIds.length > 0) {
        return (
          <>
            {profileAndUser}
            <span> shared: </span>
            {/* {goalIds.map((goalId, index) => {
              return (
                <Link
                  className="goal-link"
                  to={routes.generate(routes.profile.goal.detail, {
                    profileId,
                    goalId,
                  })}
                >
                  {profile?.goals?.[goalId]?.name || ''}
                </Link>
              );
            })} */}
          </>
        );
      }
    }

    return teamMembersIds.length > 0 ? (
      <>
        {profileAndUser}
        <span> shared with </span>
        {teamMemberNames}:
      </>
    ) : (
      profileAndUser
    );
  }, [author, data, profile, usersStore]);

  const isEditButton = useMemo(() => data.authorId === authUser.uid && dayjs(data.created_at).add(1, 'day').isAfter(dayjs()), [data, authUser]);

  const handleDismiss = useCallback(() => setEditModalOpen(false), []);

  const postTitle = useMemo(
    () => (
      <>
        <Link className="post__title__link-title" to={routes.generate('/profile/:profileId/team/:userId', {profileId: data.profileId, userId: data.authorId})}>
          {author?.firstname}
        </Link>{' '}
        posted on{' '}
        <Link className="post__title__link-title" to={routes.generate('/profile/:profileId/home/', {profileId: profile.id})}>
          {profile?.firstname} {profile?.lastname}
        </Link>
      </>
    ),
    [author?.firstname, data.authorId, data.profileId, profile?.firstname, profile.id, profile?.lastname]
  );

  return (
    <Observer threshold={0.1} onChange={inView => onViewChange(inView, data.id)}>
      <div className={`post-single ${postClass}`}>
        <div className="container">
          <div className="post">
            <div className={`post__header ${hasNotificationClass}`}>
              <Avatar className="post__avatar" src={author.profileImagePublic} />
              {isDashboardScreen ? <span className="post__title">{postTitle}</span> : <span className="post__title">{generateAuthorList()}</span>}
              <span className="post__time">
                <Link className="pinned-post" to={routes.generate('/profile/:profileId/home/:postId', {profileId: profile.id, postId: data.id})}>
                  {date}
                </Link>
              </span>
              {getIsOwner(profile) && <PostPinButton active={data.pinned && data.text} disabled={!data.text} className="post__pin" onPinToggle={onPinToggle} />}
              {isEditButton && <PostEditButton className="post__edit" onEditClick={onEditClick} />}
              {editModalOpen && <PostEditModal postText={data.text} handleDismiss={handleDismiss} onEditConfirm={onEditConfirm} />}
              {data.authorId === authUser.uid && <PostDeleteButton className="post__delete" onDeleteClick={onDeleteClick} />}
              {deleteModalOpen && <PostDeleteModal handleDismiss={() => setDeleteModalOpen(false)} onDeleteConfirm={onDeleteConfirm} />}
            </div>

            <div className="text">{renderText(profile.id, usersStore, data.text)}</div>
            {data.postImagePublic && (
              <div className="media">
                <img src={data.postImagePublic} alt="" />
              </div>
            )}
            {gallery && gallery.length > 0 && <Gallery key={`gallery-${data.id}`} mode={mode ?? 'image'} files={gallery} />}
            {files && files.length > 0 && (
              <div className="files">
                {files.map((image, idx) => (
                  <PostFile key={idx} {...image} />
                ))}
              </div>
            )}
            <NewUserWelcomeBanner data={data} />
            <UserInvited profile={profile} post={data} />
            <GoalMentioned data={data} profile={profile} />
            <GoalComplete data={data} profile={profile} />
            <GoalCreated data={data} profile={profile} />
            {errorLiking && <div className="action">Error submitting like</div>}
            <PostActions data={data} profile={profile} authUser={authUser} onLikeClick={onLikeClick} toggleCommentEnabled={toggleCommentEnabled} />
            <div className="comments">
              {commentEnabled && <CommentCreate profileId={profile.id} postId={data.id} />}
              {showMoreCommentsButton}
              {renderedComments}
            </div>
            {data.updated_at && <div className="post__edited">Post was edited {editDate}</div>}
          </div>
        </div>
      </div>
    </Observer>
  );
}

const authCondition = authUser => !!authUser;

const WrappedPost = inject('stores')(withAuthorization(authCondition)(observer(Post)));

export default WrappedPost;
