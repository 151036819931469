import {makeStyles, styled} from '@material-ui/core';

const fontFamily = {
  fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
};

const useStyles = makeStyles(theme => ({
  welcomeContainerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '1rem 0',
    marginBottom: '3rem',
    borderBottom: '1px solid #ffffff',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
    },
  },
  createPostContainer: {
    flex: '1 1 380px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 0.5em',
    },
  },
}));

const welcomeGreetingsStyle = {
  fontSize: '3rem',
  color: '#FA513A',
  margin: 0,
  padding: 0,
  ...fontFamily,
};

const sectionTitleStyle = {
  fontSize: '1.5rem',
  color: '#0C1737',
  margin: '0 0 1rem 0',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& h2': {
    fontSize: '20px',
  },
};

const sectionContainerWrapper = {
  display: 'flex',
  gap: '8rem',
  justifyContent: 'space-between',
  '&:after': {
    display: 'none',
  },
};

const ButtonsContainer = styled('div')({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
});

export {welcomeGreetingsStyle, sectionTitleStyle, sectionContainerWrapper, ButtonsContainer, useStyles};
