/* eslint-disable fp/no-mutation */
import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from '@material-ui/core';

import Avatar from '../../common/avatar';
import {Icon} from '../../common';
import {postProfileStyle, profileContainerStyle, selectProfileButton} from './CreatePostProfilesStyled';
import {getProfileFirstName, getProfileLastName, sortedProfilesByFirstName} from '../../../helpers/helperFunctions';

function CreatePostProfiles({profilesStore, isViewingProfile, activeProfile, handleUpdateSelectedProfile}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
  }, []);

  const handleProfileClick = useCallback(
    id => {
      handleUpdateSelectedProfile(undefined, id);
      handleClose();
    },
    [handleClose, handleUpdateSelectedProfile]
  );

  const buttonText = useMemo(() => {
    const text = 'Select Profile';
    let selectedUser = null;
    if (activeProfile?.id) {
      const profile = profilesStore.profiles.find(({id}) => id === activeProfile.id);

      if (profile) {
        selectedUser = (
          <>
            <Avatar src={profile?.profileSections?.essentials?.profileImage?.url || ''} className="profile-select-avatar" />
            <Typography className="name">
              {getProfileFirstName(profile)} {getProfileLastName(profile)}
            </Typography>
          </>
        );
      }
    }

    return <Box sx={profileContainerStyle}>{selectedUser || text}</Box>;
  }, [activeProfile, profilesStore.profiles]);

  if (!profilesStore.profiles.length || isViewingProfile) {
    return null;
  }

  return (
    <Box sx={postProfileStyle}>
      <Button onClick={handleClick} sx={selectProfileButton} endIcon={<Icon type="down-open" />} fullWidth disabled={anchorEl}>
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
            boxShadow: '0 0.25em 0.5em rgba(0, 0, 0, 0.05)',
          },
        }}>
        {sortedProfilesByFirstName(profilesStore.profiles).map(profile => {
          if (!profile?.id) {
            return null;
          }
          return (
            <MenuItem key={profile.id} onClick={() => handleProfileClick(profile.id)}>
              <Box sx={profileContainerStyle}>
                <ListItemIcon>
                  <Avatar src={profile?.profileSections?.essentials?.profileImage?.url} className="profile-select-avatar" />
                </ListItemIcon>
                <ListItemText>
                  {getProfileFirstName(profile)} {getProfileLastName(profile)}
                </ListItemText>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

CreatePostProfiles.defaultProps = {
  activeProfile: {},
};

CreatePostProfiles.propTypes = {
  profilesStore: PropTypes.arrayOf({
    profiles: PropTypes.shape({}),
  }).isRequired,
  isViewingProfile: PropTypes.bool.isRequired,
  handleUpdateSelectedProfile: PropTypes.func.isRequired,
  activeProfile: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default CreatePostProfiles;
