import React from 'react';

import {inject, observer} from 'mobx-react';
import {MentionsInput, Mention} from 'react-mentions';
import {withRouter} from 'react-router-dom';
import posed, {PoseGroup} from 'react-pose';

import PropTypes from 'prop-types';
import {Button} from '../../common';

import FileUpload from '../../file-upload/file-upload';
import withAuthorization from '../../session/withAuthorization';
import CreatePostProfiles from './CreatePostProfiles';

import CreatePostUploads from './CreatePostUploads';
import useCreatePost from '../../../hooks/useCreatePost';
import {createPostFooterActionStyle} from './CreatePostProfilesStyled';

const PosedSection = posed.section({
  enter: {height: 'auto', flip: true},
  exit: {height: 0},
});

function CreatePostForm(props) {
  const {
    stores: {profilesStore},
    onCancel,
  } = props;

  const {
    isViewer,
    isViewingProfile,
    activeProfile,
    text,
    error,
    uploads,
    extended,
    tags,
    isUploading,
    onSubmit,
    setExtensions,
    setText,
    renderUserSuggestion,
    renderGoals,
    handleUpdateSelectedProfile,
    onFileDelete,
    onFileUpload,
    handleOnFileProgress,
    onFileError,
  } = useCreatePost(props);

  if (isViewer) return null;

  return (
    <div className="post-create">
      <CreatePostProfiles
        profilesStore={profilesStore}
        isViewingProfile={isViewingProfile}
        activeProfile={activeProfile}
        handleUpdateSelectedProfile={handleUpdateSelectedProfile}
      />
      <div className="post-create--form">
        <form onSubmit={onSubmit}>
          <section className="section">
            <MentionsInput
              className="comments-textarea"
              value={text}
              placeholder="Share something... use @ to tag someone"
              onChange={event => {
                setExtensions(event.target.value);
                setText(event.target.value);
              }}>
              <Mention trigger="@" data={tags} renderSuggestion={renderUserSuggestion} className="comments-textarea-tagged" />
            </MentionsInput>
          </section>
          <PoseGroup>
            {extended && (
              <PosedSection className="extensions" key="extensions">
                <div className="goals-select">{renderGoals()}</div>
              </PosedSection>
            )}
          </PoseGroup>
          <CreatePostUploads uploads={uploads} onFileDelete={onFileDelete} />

          <section className="footer-action____" style={createPostFooterActionStyle}>
            {onCancel && (
              <Button type="tertiary" left onClick={onCancel}>
                Cancel
              </Button>
            )}
            <FileUpload
              inputId={`create_post_file_${activeProfile?.uid}`}
              inputName={`create_post_file_${activeProfile?.uid}`}
              onComplete={onFileUpload}
              onProgress={handleOnFileProgress}
              onError={onFileError}
              uploadIcon="attach"
              filePath={`/uploads/posts/${activeProfile?.id}/`}
            />
            <Button
              submit
              type="primary"
              right
              loading={isUploading}
              disabled={!activeProfile?.id}
              title={!activeProfile?.id ? 'Select a profile to post' : ''}>
              Post
            </Button>
            {error && <p>{error.message}</p>}
          </section>
        </form>
      </div>
    </div>
  );
}

CreatePostForm.defaultProps = {
  onCancel: undefined,
};

CreatePostForm.propTypes = {
  stores: PropTypes.shape({
    profilesStore: PropTypes.shape({
      profiles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      profileId: PropTypes.string,
    }),
  }).isRequired,
  onCancel: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  authUser: PropTypes.shape({
    uid: PropTypes.string,
  }).isRequired,
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(inject('stores')(observer(CreatePostForm))));
