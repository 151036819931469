/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';

const replaceLinks = text =>
  text.split(' ').map((wordWithBreaklines, i) => {
    const words = wordWithBreaklines.split(/(\r\n|\n|\r)/gm);
    // Check a regular expression for detecting URLs

    const components = words.map((word, j) => {
      if (word.match(/(https:\/\/[^\s]+)/g)) {
        return (
          <a key={`link-${j}`} href={word} target="_blank" rel="noopener noreferrer" className="post__link">
            {word}{' '}
          </a>
        );
      }
      return <span key={`span-${j}`}>{word} </span>;
    });

    if (components.length === 1) {
      return components;
    }
    // Add <br /> after each item in the array
    return components.map((component, j) => <Fragment key={`fragment-${j}`}>{component}</Fragment>);
  });

export default replaceLinks;
