import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {sectionTitleStyle} from './WelcomeStyled';

function SectionTitle({title}) {
  return (
    <Box sx={sectionTitleStyle}>
      <h2>{title}</h2>
    </Box>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export {SectionTitle};
export default SectionTitle;
