/* eslint-disable react/no-array-index-key */
import React, {Fragment} from 'react';
import replaceLinks from './replaceLinks';
import {UserNameLink} from '../components/user/user.link';

const renderText = (profileId, usersStore, text = '') => {
  const mentions = text.split('@[').map((str = '') => {
    if (str.length === 0) return {text: ''};
    if (str.split('](').length === 1) return {text: str};
    const name = str.split('](')[0];
    const id = str.split('](')[1].split(')')[0];
    return {
      name,
      id,
      text: replaceLinks(str.replace(name, '').replace(id, '').replace(']()', '')),
    };
  });
  return mentions.map((obj, i) => {
    if (!obj.id) {
      return replaceLinks(obj.text);
    }
    const user = usersStore.getUserById(obj.id);
    return (
      <Fragment key={`fragment - ${i}`}>
        <UserNameLink key={user.id} user={user} profileId={profileId} /> {obj.text || ''}
      </Fragment>
    );
  });
};

export default renderText;
