/* eslint-disable react/prop-types */
import React from 'react';
import {inject, observer} from 'mobx-react';
import {MentionsInput, Mention} from 'react-mentions';
import {withRouter} from 'react-router-dom';
import posed, {PoseGroup} from 'react-pose';

import {Button} from '../common';
import FileUpload from '../file-upload/file-upload';
import withAuthorization from '../session/withAuthorization';
import CreatePostUploads from '../dashboard/CreatePost/CreatePostUploads';
import useCreatePost from '../../hooks/useCreatePost';

const PosedSection = posed.section({
  enter: {height: 'auto', flip: true},
  exit: {height: 0},
});

function PostCreate(props) {
  const {
    stores: {profilesStore},
    onCancel,
  } = props;

  const {
    activeProfile,
    text,
    error,
    uploads,
    extended,
    tags,
    isViewingProfile,
    isUploading,
    isViewer,
    onSubmit,
    setExtensions,
    setText,
    renderUserSuggestion,
    renderGoals,
    handleUpdateSelectedProfile,
    onFileDelete,
    onFileUpload,
    handleOnFileProgress,
    onFileError,
  } = useCreatePost(props);

  if (isViewer) return null;

  return (
    <div className="post-create">
      <div className="heading -primary">
        <h4>Create a post</h4>
      </div>
      <form onSubmit={onSubmit} className="post-create">
        <section className="section">
          <MentionsInput
            className="comments-textarea"
            value={text}
            placeholder="Share something... use @ to tag someone"
            onChange={event => {
              setExtensions(event.target.value);
              setText(event.target.value);
            }}>
            <Mention trigger="@" data={tags} renderSuggestion={renderUserSuggestion} className="comments-textarea-tagged" />
          </MentionsInput>

          {profilesStore.profiles.length > 1 && !isViewingProfile && (
            <div className="select">
              <select onChange={handleUpdateSelectedProfile} value={activeProfile?.id}>
                {profilesStore.profiles.map(({id, firstname, lastname}) => {
                  if (id) {
                    return (
                      <option key={id} value={id}>
                        {firstname} {lastname}
                      </option>
                    );
                  }
                  return null;
                })}
              </select>
            </div>
          )}
        </section>
        <PoseGroup>
          {extended && (
            <PosedSection className="extensions" key="extensions">
              <div className="goals-select">{renderGoals()}</div>
            </PosedSection>
          )}
        </PoseGroup>
        <CreatePostUploads uploads={uploads} onFileDelete={onFileDelete} />
        <section className="footer-action">
          {onCancel && (
            <Button type="tertiary" left onClick={onCancel}>
              Cancel
            </Button>
          )}
          <FileUpload
            inputId={`create_post_file_${activeProfile?.uid}`}
            inputName={`create_post_file_${activeProfile?.uid}`}
            onComplete={onFileUpload}
            onProgress={handleOnFileProgress}
            onError={onFileError}
            uploadIcon="attach"
            filePath={`/uploads/posts/${activeProfile?.id}/`}
          />
          <Button submit type="primary" right loading={isUploading}>
            Post
          </Button>
          {error && <p>{error.message}</p>}
        </section>
      </form>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(inject('stores')(observer(PostCreate))));
