import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

function LinkAsButton({url, text, icon, variant, nomargin}) {
  const className = useMemo(() => (variant === 'outlined' ? 'client-invite-client-outlined' : 'client-invite-client'), [variant]);
  return (
    <div className="client-container-invite-link">
      <a href={url} target="_blank" rel="noopener noreferrer" className={`${className} ${nomargin ? 'no-margin' : null}`} title={text}>
        {text} {icon || null}
      </a>
    </div>
  );
}

LinkAsButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  variant: PropTypes.string,
  nomargin: PropTypes.bool,
};

LinkAsButton.defaultProps = {
  icon: null,
  variant: 'contained',
  nomargin: false,
};

export default LinkAsButton;
