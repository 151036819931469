import {makeStyles} from '@material-ui/core';

const profileStyles = makeStyles(theme => ({
  profilesWrapper: {
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  profilesContainerStyle: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '1rem',
    boxShadow: '0 0.25em 0.5em rgba(0, 0, 0, 0.05)',
    overflowY: 'scroll',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(3, 205, 183, 0.8)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(3, 205, 183, 0.1)',
    },
  },
  singleProfileContainer: {
    borderRadius: '8px',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(3, 205, 183, 0.1)',
    },
    '& .avatar .image': {
      maxWidth: '56px',
      height: '56px',
    },
    '& a': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      fontSize: '20px',
      color: '#0C1737',
      textDecoration: 'none',
    },
  },
}));

export {profileStyles};
export default profileStyles;
