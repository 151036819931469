/* eslint-disable fp/no-mutation */
import {PLACEHOLDER_PNG_SRC} from '../../constants/consts';
import {getExt} from '../helperFunctions';

const onFileProgress = (file, progress) => {
  let fileUpload = {...file};

  if (!('postFilePreview' in fileUpload)) {
    fileUpload.postFilePreview = PLACEHOLDER_PNG_SRC;

    switch (file.type.split('/')[0]) {
      case 'image':
        fileUpload.postFilePreview = URL.createObjectURL(file);

        // eslint-disable-next-line no-case-declarations
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 10;
          let {width} = image;
          let {height} = image;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          const postFilePublicLqip = canvas.toDataURL('image/jpeg');

          fileUpload = {
            ...fileUpload,
            postFile: fileUpload.name,
            ext: getExt(fileUpload.name),
            type: fileUpload.type,
            postFilePublicLqip,
          };
        };
        image.src = fileUpload.postFilePreview;

        break;

      case 'video':
        {
          // https://stackoverflow.com/questions/11261448/php-extract-frame-during-video-upload
          const url = URL.createObjectURL(file);
          const video = document.createElement('video');

          const snapshot = async () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const cw = canvas.width;
            const ch = canvas.height;

            const c = {
              // the circle: coords of the center and the radius
              x: cw / 2,
              y: ch / 2,
              r: 18,
            };

            const points = []; // the vertices array
            const angle = (2 * Math.PI) / 3;

            for (let i = 0; i < 3; i += 1) {
              const o = {};
              o.x = c.x + c.r * 0.6 * Math.cos(i * angle);
              o.y = c.y + c.r * 0.6 * Math.sin(i * angle);
              // eslint-disable-next-line fp/no-mutating-methods
              points.push(o);
            }

            context.beginPath();
            context.arc(c.x, c.y, c.r, 0, 2 * Math.PI, false);
            context.closePath();

            context.lineWidth = 3;
            context.strokeStyle = '#fff';
            context.stroke();

            // make play button
            context.fillStyle = '#fff';

            context.beginPath();
            context.moveTo(points[0].x, points[0].y);
            for (let i = 1; i < points.length; i += 1) {
              context.lineTo(points[i].x, points[i].y);
            }
            context.fill();
            context.closePath();

            context.lineWidth = 3;
            context.lineJoin = 'round';
            context.strokeStyle = '#fff';
            context.stroke();

            const screenSnapUrl = canvas.toDataURL('image/png');

            fileUpload = {
              ...fileUpload,
              postFilePreview: screenSnapUrl,
              postFile: fileUpload.name,
              ext: getExt(fileUpload.name),
              type: fileUpload.type,
            };

            video.removeEventListener('canplay', snapshot);
          };

          fileUpload.postFilePublic = url;

          video.addEventListener('canplay', snapshot);
          video.src = url;
        }
        break;

      default:
        fileUpload.postFilePreview = PLACEHOLDER_PNG_SRC;
    }

    // setUploads(prevState => ({
    //   ...prevState,
    //   [fileUpload.uniqFileName]: {
    //     ...prevState[fileUpload.uniqFileName],
    //     ...fileUpload,
    //   },
    // }));
  }
  return {
    [fileUpload.uniqFileName]: {
      ...[fileUpload.uniqFileName],
      ...fileUpload,
    },
  };
};

export {onFileProgress};
export default onFileProgress;
