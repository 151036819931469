import _ from 'lodash';
import {getFullName} from './getFullName';

function capitalizeFirstLetter(str) {
  if (!str || typeof str !== 'string') {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// eslint-disable-next-line no-bitwise
const getExt = fname => fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);

const getProfileFirstName = profile => profile?.profileSections?.essentials?.firstname || profile?.firstname || '';
const getProfileLastName = profile => profile?.profileSections?.essentials?.lastname || profile?.lastname || '';

const sortedProfilesByFirstName = profiles => _.orderBy(profiles, profile => getFullName(profile?.profileSections?.essentials).toLowerCase());

export {capitalizeFirstLetter, getExt, getProfileFirstName, getProfileLastName, sortedProfilesByFirstName};
export default capitalizeFirstLetter;
