/* eslint-disable react/prop-types */
import React from 'react';
import {withRouter} from 'react-router';
import {inject, observer} from 'mobx-react';
import {Typography} from '@material-ui/core';
import withAuthorization from '../session/withAuthorization';
import {ButtonsContainer, useStyles, welcomeGreetingsStyle} from './WelcomeStyled';
import {capitalizeFirstLetter} from '../../helpers/helperFunctions';
import LinkAsButton from '../buttons/LinkAsButton';

function Welcome({authUser}) {
  const classes = useStyles();

  return (
    <div className="container">
      <div className={classes.welcomeContainerStyle}>
        <div>
          <Typography variant="h4" style={welcomeGreetingsStyle}>
            Welcome {capitalizeFirstLetter(authUser.displayName || authUser.firstname)}
          </Typography>
        </div>
        {authUser.type === 'professional' && (
          <ButtonsContainer>
            <LinkAsButton url="https://www.sameview.com.au/refer" text="Send a Referral" nomargin />
            <LinkAsButton url="https://www.sameview.com.au/inviteclient" text="Invite a Client" variant="outlined" nomargin />
          </ButtonsContainer>
        )}
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(inject('stores')(observer(Welcome))));
