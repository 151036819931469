/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import {FileIcon, defaultStyles} from 'react-file-icon';
import {Progress, Icon} from '../../common';

function CreatePostUploads({uploads, onFileDelete}) {
  if (!uploads) {
    return null;
  }

  return (
    Object.values(uploads).length > 0 && (
      <section className="section">
        <h4>Attachments</h4>
        <div className="uploads">
          {Object.values(uploads).map((upload, idx) => {
            const [type] = (upload && upload.type && upload.type.split('/')) || '';
            return (
              <div key={upload.postFilePublic} className={`upload -${type}`}>
                {type === 'image' && (
                  <a href={upload.postFilePublic} className="upload-image" target="_blank" rel="noopener noreferrer">
                    <img src={upload.postFilePreview} alt={upload.postFile} />
                  </a>
                )}
                {type === 'video' && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video controls className="upload-video">
                    <source src={upload.postFilePublic} type={upload.type} />
                    <a className="upload-video" href={upload.postFilePublic}>
                      <img src={upload.postFilePreview} alt={upload.postFile} />
                    </a>
                  </video>
                )}
                {type !== 'image' && type !== 'video' && (
                  <a href={upload.postFilePublic} className="upload-file" target="_blank" rel="noopener noreferrer">
                    <FileIcon extension={upload.ext} {...defaultStyles[upload.ext]} />
                    <span className="upload-filename" title={upload.postFile}>
                      {upload.postFile}
                    </span>
                  </a>
                )}
                {(upload.done || upload.progress >= 100) && (
                  <button
                    type="button"
                    className="upload-delete"
                    onClick={e => {
                      e.preventDefault();
                      onFileDelete(upload);
                    }}>
                    <Icon type="minus-circled" />
                  </button>
                )}
                {!upload.done && 'progress' in upload && upload.progress < 100 && <Progress percent={upload.progress} />}
              </div>
            );
          })}
        </div>
      </section>
    )
  );
}

CreatePostUploads.defaultProps = {
  uploads: {},
};

CreatePostUploads.propTypes = {
  uploads: PropTypes.shape({}),
  onFileDelete: PropTypes.func.isRequired,
};

export default CreatePostUploads;
