/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {inject, observer} from 'mobx-react';
import {Box} from '@material-ui/core';

import withAuthorization from '../../session/withAuthorization';

import {getClientProfiles} from '../../../helpers/getClientProfiles';
import {getNonClientProfiles} from '../../../helpers/getNonClientProfiles';
import {ProfilesGroup} from './ProfilesGroup';

function Profiles({stores: {profilesStore}}) {
  const {profiles} = profilesStore;

  const removedDeletedProfiles = useMemo(() => profiles.filter(profile => profile?.id), [profiles]);

  const clientProfiles = useMemo(() => getClientProfiles(removedDeletedProfiles), [removedDeletedProfiles]);
  const nonClientProfiles = useMemo(() => getNonClientProfiles(removedDeletedProfiles), [removedDeletedProfiles]);

  return (
    <Box flex="1" mx="1%">
      <ProfilesGroup profiles={clientProfiles} title="Your Clients" />
      <ProfilesGroup profiles={nonClientProfiles} title="Your Profiles" />
    </Box>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(Profiles)));
