import React from 'react';

import {Grid} from '@material-ui/core';
import HomeFeed from '../feed/feed.home';

import Welcome from '../dashboard/Welcome';
import Profiles from '../dashboard/Profiles/Profiles';

import CreatePost from '../dashboard/CreatePost/CreatePost';

function HomePage() {
  return (
    <div>
      <div className="page">
        <Welcome />
        <div className="container noclearfix">
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={7}>
              <Profiles />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CreatePost />
            </Grid>
          </Grid>

          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={7}>
              <HomeFeed title="Recent Activity" isDashboardScreen />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
