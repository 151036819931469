import {Box} from '@material-ui/core';
import {Link} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../constants/routes';

import Avatar from '../../common/avatar';
import {profileStyles} from './ProfilesStyled';

function SingleProfile({profile}) {
  const {profileImage, firstname, lastname} = profile.profileSections?.essentials || {};
  const classes = profileStyles();
  return (
    <Box className={classes.singleProfileContainer}>
      <Link to={routes.generate(routes.profile.home, {profileId: profile.id})}>
        <Avatar src={profileImage?.url} />
        <div className="name">
          {firstname} {lastname}
        </div>
      </Link>
    </Box>
  );
}

SingleProfile.propTypes = {
  profile: PropTypes.shape({
    goals: PropTypes.shape({}),
    id: PropTypes.string,
    profileSections: PropTypes.shape({
      essentials: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        profileImage: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export {SingleProfile};
export default SingleProfile;
