const postProfileStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '20px',
  boxShadow: '0 0.25em 0.5em rgba(0, 0, 0, 0.05)',
  position: 'relative',
};

const profileContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
  },
  '& .avatar': {
    '& .image': {
      width: '31px',
      height: '31px',
    },
  },
};

const selectProfileButton = {
  fontFamily: 'inherit',
  justifyContent: 'flex-start',
  textTransform: 'none',
  padding: '10px',
};

const postProfileSelectDropdown = {
  width: '100%',
  zIndex: 1,
};

const createPostFooterActionStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1rem',
};

export {postProfileStyle, profileContainerStyle, selectProfileButton, postProfileSelectDropdown, createPostFooterActionStyle};
export default postProfileStyle;
